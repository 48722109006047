<section class="my-5" [@routerTransition]>
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-8 m-auto">
                <h5 class="header-text-update text-center"> {{'RESOURCE.QUOTES'| translate}} </h5>
                <h5 class="header-text-new pb-3 text-center"> {{MainMenu}} </h5>
                <ngb-carousel >
                  <ng-template ngbSlide *ngFor="let image of QuotesImage;">
                    <div class="picsum-img-wrapper">
                      <img class="img-align" src={{image}} alt="Random first slide">
                    </div>
                  </ng-template>
                </ngb-carousel>
            </div>
        </div>
    </div>
</section>