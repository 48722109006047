import { Component, OnInit } from '@angular/core';
import { routerTransition } from 'src/app/router.animations';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss'],
  animations: [routerTransition()]
})
export class CardsComponent implements OnInit {

  MainMenu: any;
  loading : boolean=false;
  QuotesImage: any = {}
  constructor(private auth: AuthService ) { }

  ngOnInit(): void {
    this.GetQuotes();
    this.GetStatus();
  }
  GetStatus(){
    const obj={
      userid: localStorage.getItem('user_id'),
      org_id: localStorage.getItem('org_id'),
      dimensionmenu: localStorage.getItem('menu'),
      dimensionsubmenu: localStorage.getItem('house_name'),
      token: localStorage.getItem('token')
    }
    this.auth.growth_status(obj).subscribe(
      (res:any)=>{
        this.MainMenu= res.Mainsubman;
        console.log(this.MainMenu,'main menu')
      },
      (err:any)=>{
        console.log(err,'err')
      }
    )
  }
  GetQuotes(){
    this.loading=true;
    const obj={
      token:localStorage.getItem('token'),
      dimensionmenu: localStorage.getItem('menu'),
      dimensionsubmenu: localStorage.getItem('house_name'),
    }
    this.auth.Quotes(obj).subscribe(
      (res:any)=>{
        if(res.status==true){
          let img = res.quotes[Math.floor(Math.random() * res.quotes.length)];
          this.QuotesImage=img;
          this.loading=false;
        }
      },
      (err:any)=>{
      }
    )
  }

  reloadPage(){
    window.location.reload();
  }
}
