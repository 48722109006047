import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { routerTransition } from 'src/app/router.animations';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'take-step',
  templateUrl: './take-step.component.html',
  styleUrls: ['./take-step.component.scss'],
  animations: [routerTransition()]
})

export class TakeStepComponent implements OnInit {

  constructor(private auth : AuthService, private route: ActivatedRoute,
    private router: Router) { }
  loading:boolean=false;
  challengeLevel
  challengeTask;
  MainMenu:any;
  ngOnInit(): void {
    this.GetStatus()
    this.challengeLevel = localStorage.getItem('challengeLevel');
    this.getChallengeData()
  }
  GetStatus(){
    const obj={
      userid: localStorage.getItem('user_id'),
      org_id: localStorage.getItem('org_id'),
      dimensionmenu: localStorage.getItem('menu'),
      dimensionsubmenu: localStorage.getItem('house_name'),
      token: localStorage.getItem('token')
    }
    this.auth.growth_status(obj).subscribe(
      (res:any)=>{
        this.MainMenu= res.Mainsubman;
        
      },
      (err:any)=>{
        
      }
    )
  }
  getChallengeData(){
    const obj={
      userid: localStorage.getItem('user_id'),
      org_id: localStorage.getItem('org_id'),
      dimensionmenu: localStorage.getItem('menu'),
      dimensionsubmenu: localStorage.getItem('house_name'),
      type: localStorage.getItem('challengeLevel'),
      token: localStorage.getItem('token')
    }
    this.auth.growth_challenge_sub_task(obj).subscribe(
      (res:any)=>{
       console.log(res.data)
        this.challengeTask=res.data;
      },
      (err:any)=>{
        
      }
    )
  }
  navigate(link,subtaskid){
    this.router.navigate(['./challenges',subtaskid],{relativeTo: this.route});
    console.log(subtaskid);
  }
}
