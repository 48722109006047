import { Component, OnInit } from '@angular/core';
import { routerTransition } from 'src/app/router.animations';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
  animations: [routerTransition()]
})
export class QuoteComponent implements OnInit {
  QuotesImage: any = {}
  MainMenu:any;
  constructor(private auth: AuthService) { }

  ngOnInit(): void {
    this.GetQuotes();
    this.GetStatus();
  }
  GetQuotes(){
    const obj={
      token:localStorage.getItem('token'),
      dimensionmenu: localStorage.getItem('menu'),
      dimensionsubmenu: localStorage.getItem('house_name'),
    }
    this.auth.QuotationList(obj).subscribe(
      (res:any)=>{
       
        if(res.status==true){
          let img = res.quotes[Math.floor(Math.random() * res.quotes.length)];
         
          this.QuotesImage=JSON.parse(img.images_url);
        }
      },
      (err:any)=>{
      }
    )
  }
  GetStatus(){
    const obj={
      userid: localStorage.getItem('user_id'),
      org_id: localStorage.getItem('org_id'),
      dimensionmenu: localStorage.getItem('menu'),
      dimensionsubmenu: localStorage.getItem('house_name'),
      token: localStorage.getItem('token')
    }
    this.auth.growth_status(obj).subscribe(
      (res:any)=>{
        this.MainMenu= res.Mainsubman;
        
      },
      (err:any)=>{
        
      }
    )
  }

}
