<div class="d-none d-lg-flex" style="margin-top: -3rem;margin-left: 4rem;">
    <a class="link-text"  [routerLink]="['/resources']">Resources ></a>
    <a class="link-text"  [routerLink]="['/growth-challenge']">Growth Challenge ></a>
</div>
<section class="mt-5" [@routerTransition]>
    <div class="container">
        <div class="row">
            <div class="col-12 col-xs-6">
                <h5 class="header-text-update">  {{MainMenu}} - {{challengeLevel | translate}} </h5>
                <h5 class="header-text-des pb-3 text-center"> Choose one of the following challenges: </h5>

            </div>
        </div>
        <div class="row " style="justify-content: center;">
                <div class="col-11 col-lg-3 cards mx-3 my-3" *ngFor="let task of challengeTask;" style="cursor: pointer;">
                    <a (click)="navigate('./challenges',task.sub_challanging_id)" style="text-align:center;">
                        <div class="p-2">
                            <img src="../../assets/images/icon1.svg">
                        </div>
                        <div class="p-2">
                            <h3 class="header-text-style">{{task.sub_challanging_task}}</h3>
                        </div>
                    </a>
                </div>
        </div>
        <div *ngIf="loading" class="text-center">
            <div class="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</section>