<section [@routerTransition]>
    <div *ngIf="loading" class="text-center">
        <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    <div class="container mt-0">
        <div class="row" style="margin-bottom: 10vh;">
            <div class="col-12 col-md-10 m-auto mt-0">
                <h5 class="header-text-update text-center"> {{'RESOURCE.PICK'| translate}} </h5>
                <p class="text-center header-text-new">{{MainMenu}}</p>

                <div class="m-1" style="display: flow-root;">
                    <button (click)="reloadPage()" style="float: right;" class="btn btn-primary rounded-pill"> Pick a New card </button>
                </div>
                <div class="custom-card">
                    <div class="text-center" *ngIf="QuotesImage">
                       
                        <h5 class="header-text-style mb-3">{{'RESOURCE.QUOTE' | translate}}</h5>
                    </div>
                    <div class="text-center">
                        <img src="{{QuotesImage.images_url}}" alt="Quotes" class="img-fluid img-fit" *ngIf="QuotesImage.images_url">
                      </div>
    
                    <div class="text-justify p-3">
                        <p class="text">{{QuotesImage.description}}</p>
                    </div>

                    <div class="text-right" style="color:#394C6D ;">
                        <i>Cards (including content) designed by Vanessa Jane Smith <a href="https://www.craftingconnection.com" target="_blank">(www.craftingconnection.com)</a></i>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>